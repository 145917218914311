import React from "react"
import { graphql } from "gatsby"
import { GatsbyProps } from "Src/global-types"

import StandardLayout from "Components/core/StandardLayout"
import Card, { CardImage } from "Components/Card"
import SEO from "Components/core/SEO"

interface StyleguideProps {
  data: { liamMylesIndent: CardImage }
}

const Styleguide: React.FC<StyleguideProps & GatsbyProps> = ({
  data,
  location,
}) => (
  <StandardLayout location={location}>
    <SEO title="Style Guide" />
    <Fonts />
    <br />
    <hr />
    <br />
    <Card title="Style Guide Card" imageFile={data.liamMylesIndent}>
      <>
        <a href="#" className="multiline">
          With a Multiline link class
        </a>
        <p>Takes an image created with the CardImage Fragment</p>
      </>
    </Card>
  </StandardLayout>
)
const Fonts: React.FC = () => (
  <>
    <h1>H1 - Sphinx of black quartz, judge my vow.</h1>
    <h2>H2 - Sphinx of black quartz, judge my vow.</h2>
    <h3>H3 - Sphinx of black quartz, judge my vow.</h3>
    <h4>H4 - Sphinx of black quartz, judge my vow.</h4>
    <h5>H5 - Sphinx of black quartz, judge my vow.</h5>
    <h6>H6 - Sphinx of black quartz, judge my vow.</h6>
    <p>P - Sphinx of black quartz, judge my vow.</p>
    <strong>Important - Sphinx of black quartz, judge my vow.</strong>
    <br />
    <em>Emphasized - Sphinx of black quartz, judge my vow.</em>
    <br />
    <a href="#">Link - Sphinx of black quartz, judge my vow.</a>
    <br />
    <pre>
      <code>
        {`What a world we live in
        Where you can indent`}
      </code>
    </pre>
  </>
)
export default Styleguide

export const query = graphql`
  query {
    liamMylesIndent: file(relativePath: { glob: "animation/liam*" }) {
      ...CardImage
    }
  }
`
